import { mapState, mapGetters } from 'vuex'
import RocketTracker from '@/services/RocketTracker'

export default {
    computed: {
        ...mapState({
            clinic: state => state.clinic.clinic,
            loading: state => state.booking.loading,
            bookingPages: state => state.booking.pages,
            appointment: state => state.booking.appointment,
            qrCodeBooking: state => state.booking.qrCodeBooking
        }),
        ...mapGetters({
            bookingCurrentPageIndex: 'booking/currentPageIndex',
            bookingPageAtIndex: 'booking/pageAtIndex',
            bookingTrackingData: 'booking/trackingData',
            authenticated: 'user/authenticated'
        }),
        currentIndex () { return this.bookingCurrentPageIndex(this.$route) },
    },
    methods: {
        /**
         * Switch to previous pages and sends tracking event.
         */
        previous () {
            const previousPage = this.bookingPageAtIndex(this.currentIndex - 1)
            this.$router.push({ path: `/booking/${this.clinic.stringId}/${previousPage.fields.id}` })
            // tracking event
            RocketTracker.trackEvent(
                'Booking Flow - Back',
                { 'authenticated': this.authenticated, ...this.bookingTrackingData(this.$route) },
                true
            )
        },

        /**
         * Switch to next pages and sends tracking event.
         */
        next () {
            this.$store.dispatch('booking/validateSection', this.$route).then(() => {
                const nextPage = this.bookingPageAtIndex(this.currentIndex + 1)
                this.$router.push({ path: `/booking/${this.clinic.stringId}/${nextPage.fields.id}` })
                // Mixpanel tracking
                RocketTracker.trackEvent(
                    'Booking Flow - Next',
                    { 'authenticated': this.authenticated, ...this.bookingTrackingData(this.$route) },
                    true
                )
            }).catch((error) => {
                this.$notification['error']({
                    message: 'Please fill out all required fields.'
                })

                RocketTracker.trackEvent('Error - Page Validation Failed', {
                    ...this.bookingTrackingData(this.$route),
                    'authenticated': this.authenticated,
                    'error': error
                }, true)
            })
        },

        /**
         * Submits data to API and handles payment and also checks if
         * response contains a waiver that the user has to sign.
         */
        async submit () {
            const response = await this.$store.dispatch('booking/submit', this.$route.path.indexOf('covid--self-payer--confirmation--b2b-others') > -1 ? 'b2b' : null)

            if (response.status >= 200 && response.status < 300) {
                // vaccination bookings
                if (response.status === 201) {
                    await this.pushUserToConfirmationPage()
                    return
                }

                // waiver evaluation
                if (response.data.meta && response.data.meta.requireWaiver) {
                    this.$store.commit('booking/setValue', { key: 'waiver', value: response.data.meta })
                } else {
                    await this.checkout()
                }
            } else {
                await this.handleBookingError(response)
            }
        },

        /**
         * Checkout appointment.
         * @returns {Promise<void>}
         */
        async checkout () {
            const appointmentUuid = this.$store.state.confirmationBooking.confirmationInfo.uuid
            const response = await this.$store.dispatch('booking/checkout', appointmentUuid)

            if (response.status === 200 || response.status === 201) { // payment intent created

                if (response.data.paymentMethods) {
                    this.$store.commit('booking/setStoredCreditCards', response.data.paymentMethods)
                } else {
                    this.$store.commit('booking/setStoredCreditCards', [])
                }

                this.$store.commit('confirmationBooking/setPrice', response.data.amount)
                await this.$store.dispatch('AppointmentDetails/setPrice', response.data.amount)
                await this.pushUserToConfirmationPage()
            } else {
                await this.handleBookingError(response)
            }
        },

        /**
         * Push user to confirmation page if user appointment balance is zero.
         */
        async pushUserToConfirmationPage () {
            await this.$router.push({path: '/booking/' + this.clinic.id + '/success'})
            RocketTracker.trackEvent(
                'Appointment Submitted',
                { ...this.bookingTrackingData(this.$route), 'authenticated': this.authenticated, qrCodeBooking: this.qrCodeBooking },
                true
            )
        },

        async handleBookingError (response) {
            // error handling - send push notification
            const error = response.data
            let message = ''
            if (error.errors && error.errors.length > 0) {
                for (const errorItem of error.errors) {
                    message += errorItem.msg + ' \n'

                    // show datetime modal if time is not available anymore
                    if (errorItem.param === 'datetime') {
                        // reset datetime because invalid
                        this.$store.commit('booking/setDataValue', {
                            path: 'datetime',
                            value: null
                        })
                        this.$store.commit('booking/setValue', {
                            key: 'datetimeModal',
                            value: {
                                shown: true,
                                errorMessage: errorItem.msg
                            }
                        })
                    }
                }

            } else {
                message = 'Something went wrong booking your appointment. Please try again or contact us: support@samedayhealth.com.'
            }

            this.$notification['error']({ message: 'Submit failed', description: message })

            RocketTracker.trackEvent('Error - Submitting Appointment Failed', {
                'error': message,
                'authenticated': this.authenticated
            }, true)
        }
    },
}
