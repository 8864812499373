
import { mapState } from 'vuex'
import SamedayLayout from '../../components/UserInterface/layout/SamedayLayout'
import SamedayButton from '../../components/UserInterface/button/SamedayButton'

export default {
    name: 'BookingStructureNavigationBar',
    components: { SamedayLayout, SamedayButton },
    props: {
        bookingPages: { type: Array, required: true },
        currentIndex: { type: Number, required: true },
        loading: { type: Boolean, required: true },
        fullWidth: { type: Boolean, default: false }
    },
    computed: {
        ...mapState({
            bookingPaymentPage: state => state.booking.paymentPage,
        }),
        paymentPageActive() {
            return (
                this.bookingPaymentPage &&
                this.bookingPages.length > this.currentIndex &&
                this.bookingPages[this.currentIndex].fields.id.includes('payment')
            )
        },
    },
}
